import React from "react";
import { Card, Row, Image, Footer } from "components/lib";
import { Height } from "@mui/icons-material";

export function Thanks(props) {
return (
    <>
        <Card
            className={`w-screen bg-["#F0AA61"]`}
        >
            <Row>
                <span class="flex justify-center items-center">
                    <img src={"https://i.imgur.com/4DhZKRy.png"} style={{ height: "150px" }} />
                </span>
                <Card>
                    <h1>
                        Thank you for completing the form. You will receive communication
                        from us very soon about available appointments. Keep an eye out
                        for any email communication from Katina Online. Please check your
                        spam folder.
                    </h1>
                </Card>
                <Card>
                    {/* <Footer /> */}
                </Card>
            </Row>
        </Card>
    </>
);
}
