/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, { useEffect, useMemo, useContext } from "react";
import {
  Card,
  Button,
  Stat,
  Chart,
  Link,
  AuthContext,
  Footer,
  Message,
  Grid,
  Animate,
  Feedback,
  Icon,
  ViewContext,
  TabView,
  useAPI,
} from "components/lib";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { Box, Typography } from "@mui/material";
import Axios from "axios";
import { ExportToCsv } from "export-to-csv";
import "react-quill/dist/quill.snow.css";

export function Dashboard(props) {
  // const stats = useAPI("/api/demo/stats");
  // const progress = useAPI("/api/demo/progress");
  // const table = useAPI("/api/demo/users/list");
  // const userChart = useAPI("/api/demo/users/types");
  // const revenueChart = useAPI("/api/demo/revenue");
  const user = useAPI("/api/user");
  const permission = user?.data?.permission;

  // const clientsAPI = useAPI("/api/client");
  // const practices = useAPI("/api/practice");
  // const psychUsers = useAPI("/api/psych_users");
  // const [clients, setClients] = React.useState([]);
  // const context = useContext(AuthContext);

  console.log("permission", permission);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (clientsAPI?.data && !clientsAPI?.loading) {
  //     const data = clientsAPI.data.map((c) => {
  //       return {
  //         name: c.name,
  //         id: c.id,
  //         created_at: c.created_at,
  //         active: c.active ? "Waiting" : "Completed",
  //         data: c.data,
  //         urgent: c.urgent ? "Yes" : "No",
  //         review: c.review ? "Yes" : "No",
  //       };
  //     });
  //     setClients(data);
  //   }
  // }, [clientsAPI.data]);

  // const toggleUrgent = async (row) => {
  //   console.log("row", row);
  //   await Axios.patch("/api/client/urgent/" + row.id, {
  //     urgent: row.urgent === "Yes" ? false : true,
  //     id: row.id,
  //   });
  //   const updatedClients = clients.map((c) => {
  //     if (c.id === row.id) {
  //       return { ...c, urgent: c.urgent === "Yes" ? "No" : "Yes" };
  //     }
  //     return c;
  //   });
  //   setClients(updatedClients);
  //   // window.location.reload();
  // };

  // const columns = useMemo(
  //   () => [
  //     {
  //       accessorKey: "name",
  //       header: "Name",
  //     },
  //     // {
  //     //   accessorKey: "created_at",
  //     //   header: "Created At",
  //     // },
  //     {
  //       accessorFn: (row) => {
  //         const date = new Date(row.created_at);
  //         return `${date.getFullYear()}-${
  //           date.getMonth() + 1
  //         }-${date.getDate()}`;
  //       },
  //       id: "date",
  //       header: "Created At",
  //     },
  //     {
  //       accessorKey: "active",
  //       header: "Status",
  //     },
  //     {
  //       accessorFn: (row) => {
  //         return Math.trunc(row?.data?.Age);
  //       },
  //       id: "age",
  //       header: "Age",
  //     },
  //     {
  //       accessorKey: "data.service",
  //       id: "service",
  //       header: "Service",
  //     },
  //     {
  //       accessorFn: (row) => {
  //         return row?.data?.mainReason?.join(", ");
  //       },
  //       id: "presentingIssues",
  //       header: "Presenting Issues",
  //     },
  //     {
  //       accessorKey: "urgent",
  //       header: "Urgent",
  //     },
  //     {
  //       accessorKey: "review",
  //       header: "Review",
  //     },
  //   ],
  //   []
  // );

  // const reviewColumns = useMemo(
  //   () => [
  //     {
  //       accessorKey: "name",
  //       header: "Name",
  //     },
  //     {
  //       accessorFn: (row) => {
  //         return row?.data?.mainReason?.join(", ");
  //       },
  //       id: "presentingIssues",
  //       header: "Presenting Issues",
  //     },
  //   ],
  //   []
  // );

  if (permission === "admin" || permission === "owner") {
    return <AdminDashboard />;
  } else if (permission === "user") {
    return <UserDashboard />;
  }

  // return (
  //   <Animate type="pop">
  //     {/* <Message
  //       closable
  //       title="Welcome to Katina Online!"
  //       text="This is a sample dashboard to get you started. Please read the documentation to learn how to build your own features."
  //       type="info"
  //     /> */}

  //     <Grid cols="5">
  //       <Stat
  //         loading={clientsAPI?.loading}
  //         value={clientsAPI?.data?.filter((c) => c.active).length}
  //         label="active"
  //         icon="users"
  //       />
  //       <Stat
  //         loading={clientsAPI?.loading}
  //         value={clients?.filter((c) => c.urgent === "Yes").length}
  //         label="urgent"
  //         icon="alert-triangle"
  //       />
  //       <Stat
  //         loading={clientsAPI?.loading}
  //         value={clientsAPI?.data?.filter((c) => c.review).length}
  //         label="review"
  //         icon="stop-circle"
  //       />
  //       <Stat
  //         loading={practices?.loading}
  //         value={practices?.data?.length}
  //         label="Locations"
  //         icon="calendar"
  //       />
  //       <Stat
  //         loading={psychUsers?.loading}
  //         value={psychUsers?.data?.length}
  //         label="Psychologists"
  //         icon="user"
  //       />
  //     </Grid>

  //     <Grid cols="2">
  //       <Card title="Quick Reference">
  //         <ul>
  //           <li>
  //             1. <Link url="/account/practice" text="Add Locations" />
  //           </li>
  //           <li>
  //             2. <Link url="/account/users" text="Invite your team members" />
  //           </li>
  //           <li>
  //             3.{" "}
  //             <Link
  //               url={`/public/waitlist/${context?.user?.account_id}`}
  //               target="_blank"
  //               text="Client Form Link for the website"
  //             />
  //           </li>
  //           <li>
  //             4.{" "}
  //             <Link
  //               url="/"
  //               text="Review your client/practitioner matches (TBD)"
  //             />
  //           </li>
  //         </ul>
  //       </Card>

  //       <Card title="For Review">
  //         <MaterialReactTable
  //           data={clients?.filter((c) => c.review === "Yes")}
  //           columns={reviewColumns}
  //           enableRowActions
  //           positionActionsColumn="last"
  //           renderRowActions={(row, index) => [
  //             <>
  //               <Button
  //                 action={(row) => {
  //                   console.info("View", row);
  //                   navigate("/client/" + row.row.original.id);
  //                 }}
  //                 params={row}
  //                 small
  //                 text="View"
  //               />
  //             </>,
  //           ]}
  //         />
  //       </Card>
  //     </Grid>

  //     {/* <Card name="revenues" title="Revenue">
  //       <Chart
  //         type="line"
  //         legend
  //         loading={revenueChart.loading}
  //         data={revenueChart.data}
  //         color={["red", "blue"]}
  //       />
  //     </Card> */}
  //     {/*
  //     <Grid cols="2">
  //       <Card title="Goals" loading={progress.loading}>
  //         {progress?.data?.map((item) => {
  //           return (
  //             <ProgressBar
  //               key={item.label}
  //               label={item.label}
  //               progress={item.value}
  //             />
  //           );
  //         })}
  //       </Card>
  //       <Card title="User Types">
  //         <Chart
  //           type="pie"
  //           legend={true}
  //           data={userChart.data}
  //           loading={userChart.loading}
  //           color="purple"
  //         />
  //       </Card>
  //     </Grid> */}

  //     {/* <Card title="Client Waitlist" last>
  //       <Table
  //         search
  //         data={clients}
  //         loading={clientsAPI.loading}
  //         onClick={(row) => console.log("row", row)}
  //         badge={{ col: "active", color: "blue" }}
  //       ></Table>
  //     </Card> */}

  //     <Card title="Client Waitlist">
  //       <MaterialReactTable
  //         title="Verifications"
  //         columns={columns}
  //         data={clients}
  //         enableRowActions
  //         enableSelectAll
  //         enableColumnVisibility
  //         enableRowSelection={true}
  //         muiTableBodyRowProps={(row) => {
  //           if (row.row.original.urgent === "Yes") {
  //             return {
  //               style: {
  //                 backgroundColor: "red",
  //               },
  //             };
  //           }
  //         }}
  //         positionActionsColumn="last"
  //         initialState={{
  //           columnVisibility: {
  //             active: false,
  //             urgent: false,
  //             review: false,
  //           },
  //           sorting: [
  //             { id: "urgent", desc: true },
  //             { id: "date", desc: true },
  //           ],
  //         }}
  //         renderRowActions={(row, index) => [
  //           <>
  //             <Button
  //               action={(row) => {
  //                 console.info("View", row);
  //                 navigate("/client/" + row.row.original.id);
  //               }}
  //               params={row}
  //               small
  //               text="View"
  //             />
  //             {row.row.original.urgent === "Yes" ? (
  //               <Button
  //                 textOnly
  //                 text="Mark as Normal"
  //                 action={toggleUrgent}
  //                 params={row.row.original}
  //               ></Button>
  //             ) : (
  //               <Button
  //                 text="⚠ Mark As Urgent"
  //                 textOnly
  //                 action={toggleUrgent}
  //                 params={row.row.original}
  //               ></Button>
  //             )}
  //           </>,
  //         ]}
  //       />
  //     </Card>

  //     <Feedback />
  //   </Animate>
  // );
}

function AdminDashboard(props) {
  const clientsAPI = useAPI("/api/client");
  const practices = useAPI("/api/practice");
  const psychUsers = useAPI("/api/psych_users");
  const [clients, setClients] = React.useState([]);
  const context = useContext(AuthContext);
  const [value, setValue] = React.useState("");
  const [issues, setIssues] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const revenueChart = useAPI("/api/client/chart");

  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);

  useEffect(() => {
    if (clientsAPI?.data && !clientsAPI?.loading) {
      const data = clientsAPI.data.map((c) => {
        return {
          name: c.name,
          id: c.id,
          created_at: c.created_at,
          active: c.active ? "Waiting" : "Completed",
          data: c.data,
          urgent: c.urgent ? "Yes" : "No",
          review: c.review ? "Yes" : "No",
          admin_review: c.admin_review ? "Yes" : "No",
          priority: c.priority ? "Yes" : "No",
          comment: c.comment,
          email: c.data.email,
          phone: c.data.phone,
        };
      });
      setClients(data);
    }
  }, [clientsAPI.data]);

  const toggleUrgent = async (row) => {
    console.log("row", row);
    await Axios.patch("/api/client/urgent/" + row.id, {
      urgent: row.urgent === "Yes" ? false : true,
      id: row.id,
    });
    const updatedClients = clients.map((c) => {
      if (c.id === row.id) {
        return { ...c, urgent: c.urgent === "Yes" ? "No" : "Yes" };
      }
      return c;
    });
    setClients(updatedClients);
    // window.location.reload();
  };

  const archive = async ({ id, name }) => {
    console.log("id", id);
    console.log("name", name);
    viewContext.modal.show(
      {
        title: `Archive this client?`,
        form: {},
        buttonText: "Archive",
        text: `Are you sure?`,
        url: `/api/client/archive/${id}`,
        method: "delete",
      },
      (res) => {
        // console.log("in cb", res);
        console.log("res", res);
        window.location = "/dashboard";
      }
    );
  };

  const handleEmail = async () => {
    const ids = Object.keys(rowSelection);
    console.log("ids", ids);
    const clients = clientsAPI.data.filter((c) => ids.includes(c.id));
    console.log("clients", clients);
    // redirect to bulk page with emails as query params
    window.location = `/bulk?ids=${ids.join(",")}`;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 50,
      },
      // {
      //   accessorKey: "created_at",
      //   header: "Created At",
      // },
      {
        accessorFn: (row) => {
          const date = new Date(row.created_at);
          return `${date.getFullYear()}-${
            date.getMonth() + 1
          }-${date.getDate()}`;
        },
        id: "date",
        header: "Created At",
        size: 30,
      },
      {
        accessorFn: (row) => {
          const date = new Date(row.created_at);
          const now = new Date();
          const diff = now.getTime() - date.getTime();

          const days = Math.trunc(diff / (1000 * 60 * 60 * 24));
          return days;
        },
        id: "wlDays",
        header: "WL Days",
        size: 30,
      },
      {
        accessorKey: "active",
        header: "Status",
      },
      {
        accessorFn: (row) => {
          return Math.trunc(row?.data?.Age);
        },
        id: "age",
        header: "Age",
        filterVariant: "range",
        filterFn: "between",
        size: 30,
      },
      {
        accessorKey: "data.service",
        id: "service",
        header: "Service",
        size: 50,
      },
      {
        accessorFn: (row) => {
          return row?.data?.mainReason?.join(", ");
        },
        id: "presentingIssues",
        header: "Presenting Issues",
      },
      {
        accessorFn: (row) => {
          console.log("row", row?.data?.availPanel);
          const practices = row?.data?.availPanel.map((l) => l.practiceName);
          return practices.join(", ");
        },
        id: "location",
        header: "Location",
        size: 50,
      },
      {
        accessorKey: "urgent",
        header: "Urgent",
      },
      {
        accessorKey: "review",
        header: "Review",
      },
      {
        accessorKey: "priority",
        header: "Priority",
      },
      {
        accessorFn: (row) => {
          return row?.data?.uploadedDocs ? "Yes" : "No";
        },
        id: "attachments",
        header: "Attachments",
      },
      {
        header: "Funding Source",
        accessorFn: (row) => {
          return row?.data?.whoPays;
        },
        id: "fundingSource",
      },
      {
        header: "Email",
        accessorKey: "email",
      },
      {
        header: "Phone",
        accessorKey: "phone",
      },
    ],
    []
  );

  const reviewColumns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorFn: (row) => {
          return row?.data?.mainReason?.join(", ");
        },
        id: "presentingIssues",
        header: "Presenting Issues",
      },
      {
        header: "Legal",
        accessorFn: (row) => {
          return row?.data?.legalMatters ? "Yes" : "No";
        },
      },
      {
        header: "Current Client",
        accessorFn: (row) => {
          return row?.data?.currentClient ? "Yes" : "No";
        },
      },
      {
        header: "Location",
        accessorFn: (row) => {
          const practices = row?.data?.availPanel.map((l) => l.practiceName);
          return practices.join(", ");
        },
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: [
      "Name",
      "Created At",
      "Age",
      "Email",
      "Location",
      "Phone",
      "Service",
      "Urgent",
      "Review",
      "Priority",
    ],
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    console.log("in ex", clients);
    const toExports = clients.map((c) => {
      return {
        name: c.name,
        created_at: c.created_at,
        Age: c.data.Age || "no age",
        email: c.data.email || "no email",
        location:
          c.data.availPanel.map((l) => l.practiceName).join(", ") || "None",
        phone: c.data.phone || "no phone",
        service: c.data.service || "no service",
        urgent: c.urgent ? "Yes" : "No",
        review: c.review ? "Yes" : "No",
        admin_review: c.admin_review ? "Yes" : "No",
        priority: c.priority ? "Yes" : "No",
      };
    });

    csvExporter.generateCsv(toExports);
  };

  return (
    <Animate type="pop">
      {/* <Message
        closable
        title="Welcome to Katina Online!"
        text="This is a sample dashboard to get you started. Please read the documentation to learn how to build your own features."
        type="info"
      /> */}

      <Grid cols="6">
        <Card>
          <Icon
            color="dark"
            image="users"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max group">
            <p className="text-2xl font-bold">
              {/* <Button text="View" textOnly /> */}
              {/* <Link url={`/client/${id}`}>View</Link> */}
              {clientsAPI?.data?.filter((c) => c.active).length}
            </p>
            <p className="text-sm text-slate-400">
              Client
              <Link
                url={`/public/waitlist/${context?.user?.account_id}`}
                target="_blank"
              >
                {" "}
                (Form)
                <span class="opacity-0 invisible bg-gray-800 text-white text-sm rounded-md py-2 absolute z-10 group-hover:opacity-100 group-hover:visible transition">
                  Click here to access the client form. 
                </span>
              </Link>
              <span class="text-slate-400 text-lg">ⓘ</span>
            </p>
          </div>
        </Card>
        {/* <Stat
          loading={clientsAPI?.loading}
          value={clientsAPI?.data?.filter((c) => c.active).length}
          label="active"
          icon="users"
        /> */}
        {/* <Stat
          loading={clientsAPI?.loading}
          value={clientsAPI?.data?.filter((c) => c.review).length}
          label="clinical review"
          icon="stop-circle"
        /> */}
        <Card>
          <Icon
            color="dark"
            image="stop-circle"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2 bg-[#D3D3D3]"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {/* <Button text="View" textOnly /> */}
              {/* <Link url={`/client/${id}`}>View</Link> */}
              {/* {practices?.data?.length} */}
              {clientsAPI?.data?.filter((c) => c.review).length}
            </p>
            <p className="text-sm text-slate-400">
              {/* <span className="inline-block bg-[#D3D3D3]">1</span> */}
              clinical review
              {/* <Link url={"/account/practice"} target="_blank"> */}
              {/* {" "} */}
              {/* (Add) */}
              {/* </Link> */}
            </p>
          </div>
        </Card>
        {/* <Stat
          loading={clientsAPI?.loading}
          value={clientsAPI?.data?.filter((c) => c.admin_review).length}
          label="admin review"
          icon="x-octagon"
        /> */}
        <Card>
          <Icon
            color="dark"
            image="x-octagon"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2 bg-[#90EE90]"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {/* <Button text="View" textOnly /> */}
              {/* <Link url={`/client/${id}`}>View</Link> */}
              {/* {practices?.data?.length} */}
              {clientsAPI?.data?.filter((c) => c.admin_review).length}
            </p>
            <p className="text-sm text-slate-400">
              <span className="inline-block bg-[#90EE90]"></span>
              admin review
              {/* <Link url={"/account/practice"} target="_blank"> */}
              {/* {" "} */}
              {/* (Add) */}
              {/* </Link> */}
            </p>
          </div>
        </Card>
        {/* <Stat
          loading={clientsAPI?.loading}
          value={clients?.filter((c) => c.urgent === "Yes").length}
          label="urgent"
          icon="alert-triangle"
        /> */}
        <Card>
          <Icon
            color="dark"
            image="alert-triangle"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2 bg-[#d95565]"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {/* <Button text="View" textOnly /> */}
              {/* <Link url={`/client/${id}`}>View</Link> */}
              {/* {practices?.data?.length} */}
              {clients?.filter((c) => c.urgent === "Yes").length}
            </p>
            <p className="text-sm text-slate-400">
              {/* <span className="inline-block bg-[#d95565]"></span> */}
              urgent
              {/* <Link url={"/account/practice"} target="_blank"> */}
              {/* {" "} */}
              {/* (Add) */}
              {/* </Link> */}
            </p>
          </div>
        </Card>
        {/* <Stat
          loading={practices?.loading}
          value={practices?.data?.length}
          label="Locations"
          icon="calendar"
        /> */}
        <Card>
          <Icon
            color="dark"
            image="arrow-up"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2 bg-[#FFF700]"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {/* <Button text="View" textOnly /> */}
              {/* <Link url={`/client/${id}`}>View</Link> */}
              {/* {practices?.data?.length} */}
              {clientsAPI?.data?.filter((c) => c.priority).length}
            </p>
            <p className="text-sm text-slate-400">
              {/* <span className="inline-block bg-[#FFF700]"></span> */}
              priority
              {/* <Link url={"/account/practice"} target="_blank"> */}
              {/* {" "} */}
              {/* (Add) */}
              {/* </Link> */}
            </p>
          </div>
        </Card>
        {/* <Stat
          loading={psychUsers?.loading}
          value={psychUsers?.data?.length}
          label="Psychologists"
          icon="user"
        /> */}
        <Card>
          <Icon
            color="dark"
            image="user"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {/* <Button text="View" textOnly /> */}
              {/* <Link url={`/client/${id}`}>View</Link> */}
              {psychUsers?.data?.length}
            </p>
            <p className="text-sm text-slate-400">
              Users
              <Link url={"/account/users"} target="_blank">
                {" "}
                (Manage)
              </Link>
            </p>
          </div>
        </Card>
      </Grid>

      <Card>
        <Chart
          type="line"
          legend
          loading={revenueChart.loading}
          data={revenueChart.data}
          color={["blue"]}
        />
      </Card>

      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
        {/* <div style={{ paddingRight: "10px" }}>
          <Card title="Quick Reference" restrictWidth>
            <ul>
              <li>
                1. <Link url="/account/practice" text="Add Locations" />
              </li>
              <li>
                2. <Link url="/account/users" text="Invite your team members" />
              </li>
              <li>
                3.{" "}
                <Link
                  url={`/public/waitlist/${context?.user?.account_id}`}
                  target="_blank"
                  text="Client Form Link for the website"
                />
                <Link url="/instructions" target="_blank">
                  (instructions)
                </Link>
              </li>
              <li>
                4.{" "}
                <Link
                  url="/account/users"
                  text="Review your client/practitioner matches"
                  target="_blank"
                />
              </li>
            </ul>
          </Card>
        </div> */}

        <TabView name="Review" labels={["Clinical", "Admin"]}>
          <Card className={props.className}>
            <div>
              <Card title="For Review" wide>
                <Message
                  type="info"
                  text="Clients with Suicidal Thinking, Self Harming Behaviours, Post-natal Depression/Anxiety, Eating Disorders, School Refusal, Legal Matters, Schizophrenia, BiPolar Disorder,or a current client of the practice will be flagged for review."
                />
                <MaterialReactTable
                  data={clients?.filter((c) => c.review === "Yes")}
                  columns={reviewColumns}
                  enableRowActions
                  renderDetailPanel={({ row }) => {
                    const client = clientsAPI?.data?.find(
                      (c) => c.id === row.original.id
                    );

                    const {
                      therapySubCategories,
                      mentalHealthSubCategories,
                      generalHealthSubCategories,
                      traumaSubCategories,
                      personalSubCategories,
                      genderSubCategories,
                      relationshipSubCategories,
                      educationalSubCategories,
                      addictionSubCategories,
                      workSubCategories,
                      legalSubCategories,
                      subPersonalityDisorders,
                      eatingSubCategories,
                      anxietySubCategories,
                      occupationalTherapySubCategories,
                      occupationalTherapyAssessmentSubCategories
                    } = client.data;

                    const otherIssues = []
                      .concat(
                        therapySubCategories,
                        mentalHealthSubCategories,
                        generalHealthSubCategories,
                        traumaSubCategories,
                        personalSubCategories,
                        genderSubCategories,
                        relationshipSubCategories,
                        educationalSubCategories,
                        addictionSubCategories,
                        workSubCategories,
                        legalSubCategories,
                        subPersonalityDisorders,
                        eatingSubCategories,
                        anxietySubCategories,
                        occupationalTherapySubCategories,
                        occupationalTherapyAssessmentSubCategories
                      )
                      .filter((c) => c !== undefined);

                    return (
                      <Box
                        sx={{
                          display: "grid",
                          margin: "auto",
                          gridTemplateColumns: "1fr 1fr",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Sub category:</strong>{" "}
                          {otherIssues.join(", ") || "-"}
                        </Typography>
                        <Typography>
                          <strong>Comments:</strong>
                          <span
                            dangerouslySetInnerHTML={{ __html: client.comment }}
                          ></span>{" "}
                          (
                          <Link
                            url={`/match/${client.id}`}
                            text="Click to edit"
                          ></Link>
                          )
                        </Typography>
                      </Box>
                    );
                  }}
                  renderRowActions={(row, index) => [
                    <>
                      <Button
                        action={(row) => {
                          console.info("View", row);
                          window.open(
                            "/client/" + row.row.original.id,
                            "_blank"
                          );
                        }}
                        params={row}
                        small
                        text="View"
                      />
                    </>,
                  ]}
                />
              </Card>
            </div>
          </Card>
          <Card className={props.className}>
            <div>
              <Card title="For Review" wide>
                <Message
                  type="info"
                  text="Clients with Suicidal Thinking, Self Harming Behaviours, Post-natal Depression/Anxiety, Eating Disorders, School Refusal, Legal Matters, Schizophrenia, BiPolar Disorder,or a current client of the practice will be flagged for review."
                />
                <MaterialReactTable
                  data={clients?.filter((c) => c.admin_review === "Yes")}
                  columns={reviewColumns}
                  enableRowActions
                  renderDetailPanel={({ row }) => {
                    const client = clientsAPI?.data?.find(
                      (c) => c.id === row.original.id
                    );

                    const {
                      therapySubCategories,
                      mentalHealthSubCategories,
                      generalHealthSubCategories,
                      traumaSubCategories,
                      personalSubCategories,
                      genderSubCategories,
                      relationshipSubCategories,
                      educationalSubCategories,
                      addictionSubCategories,
                      workSubCategories,
                      legalSubCategories,
                      subPersonalityDisorders,
                      eatingSubCategories,
                      anxietySubCategories,
                      occupationalTherapySubCategories,
                      occupationalTherapyAssessmentSubCategories
                    } = client.data;

                    const otherIssues = []
                      .concat(
                        therapySubCategories,
                        mentalHealthSubCategories,
                        generalHealthSubCategories,
                        traumaSubCategories,
                        personalSubCategories,
                        genderSubCategories,
                        relationshipSubCategories,
                        educationalSubCategories,
                        addictionSubCategories,
                        workSubCategories,
                        legalSubCategories,
                        subPersonalityDisorders,
                        eatingSubCategories,
                        anxietySubCategories,
                        occupationalTherapySubCategories,
                        occupationalTherapyAssessmentSubCategories
                      )
                      .filter((c) => c !== undefined);

                    return (
                      <Box
                        sx={{
                          display: "grid",
                          margin: "auto",
                          gridTemplateColumns: "1fr 1fr",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Sub category:</strong>{" "}
                          {otherIssues.join(", ") || "-"}
                        </Typography>
                        <Typography>
                          <strong>Comments:</strong>
                          <span
                            dangerouslySetInnerHTML={{ __html: client.comment }}
                          ></span>{" "}
                          (
                          <Link
                            url={`/match/${client.id}`}
                            text="Click to edit"
                          ></Link>
                          )
                        </Typography>
                      </Box>
                    );
                  }}
                  renderRowActions={(row, index) => [
                    <>
                      <Button
                        action={(row) => {
                          console.info("View", row);
                          window.open(
                            "/client/" + row.row.original.id,
                            "_blank"
                          );
                        }}
                        params={row}
                        small
                        text="View"
                      />
                    </>,
                  ]}
                />
              </Card>
            </div>
          </Card>
        </TabView>
      </div>

      <br />

      {/* <Card name="revenues" title="Revenue">
        <Chart
          type="line"
          legend
          loading={revenueChart.loading}
          data={revenueChart.data}
          color={["red", "blue"]}
        />
      </Card> */}
      {/* 
      <Grid cols="2">
        <Card title="Goals" loading={progress.loading}>
          {progress?.data?.map((item) => {
            return (
              <ProgressBar
                key={item.label}
                label={item.label}
                progress={item.value}
              />
            );
          })}
        </Card>
        <Card title="User Types">
          <Chart
            type="pie"
            legend={true}
            data={userChart.data}
            loading={userChart.loading}
            color="purple"
          />
        </Card>
      </Grid> */}

      {/* <Card title="Client Waitlist" last>
        <Table
          search
          data={clients}
          loading={clientsAPI.loading}
          onClick={(row) => console.log("row", row)}
          badge={{ col: "active", color: "blue" }}
        ></Table>
      </Card> */}

      <Card title="Client Waitlist">
        <MaterialReactTable
          title="Client Waitlist"
          columns={columns}
          data={clients}
          enableRowActions
          // enableRowNumbers
          enableSelectAll
          rowsPerPage={100}
          enableColumnVisibility
          enableRowSelection={true}
          // globalFilterFn="contains"
          renderDetailPanel={({ row }) => {
            const client = clientsAPI?.data?.find(
              (c) => c.id === row.original.id
            );

            const {
              therapySubCategories,
              mentalHealthSubCategories,
              generalHealthSubCategories,
              traumaSubCategories,
              personalSubCategories,
              genderSubCategories,
              relationshipSubCategories,
              educationalSubCategories,
              addictionSubCategories,
              workSubCategories,
              legalSubCategories,
              subPersonalityDisorders,
              eatingSubCategories,
              anxietySubCategories,
            } = client.data;

            const otherIssues = []
              .concat(
                therapySubCategories,
                mentalHealthSubCategories,
                generalHealthSubCategories,
                traumaSubCategories,
                personalSubCategories,
                genderSubCategories,
                relationshipSubCategories,
                educationalSubCategories,
                addictionSubCategories,
                workSubCategories,
                legalSubCategories,
                subPersonalityDisorders,
                eatingSubCategories,
                anxietySubCategories
              )
              .filter((c) => c !== undefined);

            return (
              <Box
                sx={{
                  display: "grid",
                  margin: "auto",
                  gridTemplateColumns: "1fr 1fr",
                  width: "100%",
                }}
              >
                <Typography>
                  <strong>Email:</strong>{" "}
                  <a href={`mailto:${client.data.email}`}>
                    {client.data.email}
                  </a>
                </Typography>
                <Typography>
                  <strong>Phone:</strong> {client.data.phone}
                </Typography>
                <Typography>
                  <strong>Sub category:</strong> {otherIssues.join(", ") || "-"}
                </Typography>
                <Typography>
                  <strong>Additional Info:</strong> {client.data.additionalInfo}
                </Typography>
                {client.data.assessments && (
                  <Typography>
                    <strong>Assessments:</strong> {client.data.assessments}
                  </Typography>
                )}
                <Typography>
                  <strong>Comments:</strong>
                  <span
                    dangerouslySetInnerHTML={{ __html: client.comment }}
                  ></span>{" "}
                  (
                  <Link url={`/match/${client.id}`} text="Click to edit"></Link>
                  )
                </Typography>
                <Typography>
                  <Button
                    text="Archive"
                    action={archive}
                    small
                    rounded
                    color="red"
                    params={{ id: client.id, name: client.name }}
                  />
                </Typography>
              </Box>
            );
          }}
          muiTableBodyRowProps={(row) => {
            if (row.row.original.urgent === "Yes") {
              return {
                style: {
                  backgroundColor: "#d95565",
                },
              };
            }

            if (row.row.original.review === "Yes") {
              return {
                style: {
                  backgroundColor: "#D3D3D3",
                },
              };
            }

            if (row.row.original.admin_review === "Yes") {
              return {
                style: {
                  backgroundColor: "#90EE90",
                },
              };
            }

            if (row.row.original.priority === "Yes") {
              return {
                style: {
                  backgroundColor: "#FFF700",
                },
              };
            }
          }}
          renderTopToolbarCustomActions={() => (
            <>
              <div className="ml-auto pt-2">
                <Button
                  action={handleEmail}
                  icon="mail"
                  color="black"
                  textOnly
                  outline
                  alignLeft
                  text="Email"
                />
                <Button
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  action={handleExportData}
                  icon="download"
                  text="Download"
                  textOnly
                  outline
                  alignLeft
                />
              </div>
            </>
          )}
          positionActionsColumn="last"
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          getRowId={(originalRow) => originalRow.id}
          initialState={{
            columnVisibility: {
              active: false,
              urgent: false,
              review: false,
              priority: false,
              phone: false,
              email: false,
            },
            pagination: { pageSize: 100 },
            sorting: [
              { id: "urgent", desc: true },
              { id: "date", desc: true },
            ],
          }}
          renderRowActions={(row, index) => [
            <>
              <Button
                action={(row) => {
                  console.info("View", row);
                  window.open("/match/" + row.row.original.id, "_blank");
                }}
                params={row}
                small
                text="View"
              />
              {/* {row.row.original.urgent === "Yes" ? (
                <Button
                  textOnly
                  text="Mark as Normal"
                  action={toggleUrgent}
                  params={row.row.original}
                ></Button>
              ) : (
                <Button
                  text="⚠ Mark As Urgent"
                  textOnly
                  action={toggleUrgent}
                  params={row.row.original}
                ></Button>
              )} */}
            </>,
          ]}
        />
      </Card>

      <Feedback />
      <Footer />
    </Animate>
  );
}

function UserDashboard(props) {
  const context = useContext(AuthContext);

  return (
    <Grid cols="2">
      <Card title="Quick Reference">
        <ul>
          <li>
            1.{" "}
            <Link
              url={`/public/waitlist/${context?.user?.account_id}`}
              target="_blank"
              text="Client Form Link for the website"
            />
          </li>
          <li>
            2.{" "}
            <Link
              url="/account/psych/confirm"
              text="Update your profile and availability"
            />
          </li>
        </ul>
      </Card>
      <Footer />
    </Grid>
  );
}
